<template>
  <div>
    <title>แลกของรางวัล</title>
    <img src="../assets/image/UI Member PHOTO/Banner-04.png" class="img-fluid" alt="Responsive image" style="width: 100%;" />

      <!-- <div>
      <CCard style="background-color:#f3f3f3;">
        <CCardBody>
          <div style="display: flex;">
            <div style="margin: auto auto;">
              <h3 style="font-size:16px;">คะแนนสะสม</h3>
              <div style="display: flex;">
                <div>
                  <img src="../assets/image/UI Member PHOTO/Reward icon-01.png" style="width: 25px;" />
                </div>
                <div>
                  <h2 style="font-size:20px;">0</h2>
                </div>
              </div>
            </div>
            <div style="margin: auto auto;">
              <center>
                <p style="font-size:12px; color:#7D7D7D; margin: 0 0 3px 0;">โอนคะแนนสะสม</p>
                <CButton color="secondary" style="margin: 0 10px;">
                  <p style="width: 50px; margin: 0px 0px;">โอน</p>
                </CButton>
              </center>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div> -->

    <div style="width: 95%; margin:auto; padding-top:10px;">

      <!-- <CCard style="background-color:#f3f3f3;"> -->

        <!-- <CCardBody style="background-color:#e5e5e5;">
          <center><p style="font-size:14px; margin: 0 0 5px 0;">แลกของรางวัล</p></center>
          <CCard>
            <CCardBody style="padding: 0px;">
              <img src="../assets/image/UI Member PHOTO/Reward Banner-01.png" v-on:click="changeToPageTrueYou()" style="width: 100%;" />
            </CCardBody>
          </CCard> -->

          <!-- <CCard>
            <CCardBody style="padding: 0px;">
              <img src="../assets/image/UI Member PHOTO/Reward Banner-04.png" style="width: 100%;" />
            </CCardBody>
          </CCard>

          <CCard>
            <CCardBody style="padding: 0px;">
              <img src="../assets/image/UI Member PHOTO/Reward Banner-04.png" style="width: 100%;" />
            </CCardBody>
          </CCard> -->
        <!-- </CCardBody>
      </CCard> -->
    </div>
  </div>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";

import { freeSet } from "@coreui/icons";
import config from '../config'
export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      regisModal: false,
      selected: "Month",
      name: "AOF",
      nameUser: "คุณ วันเดอร์ ซักสะอาด",
      tabs: null,
     
      date: null,
      menu: false,

    };
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  mounted() {
    this.regisModal = this.$store.state.popupregis
    console.log("this.regisModal",this.regisModal)
    var listkey = Object.keys(this.$store.state.profile)   
    if(listkey.length <= 3){
      this.$router.push('/dashboard?pageww=reward')
    }
    // this.$store.state.popupregis = this.$store.state.popupregis_buff
    this.profile = this.$store.state.profile
    
  },
  methods: {
    changeToPageTrueYou() {
      this.$router.push("/tureyouww");
    },
    save (date) {
      this.$refs.menu.save(date)
      this.date = this.date.toString()
      var datelist = this.date.split("-")
      this.date = datelist[2] +"/"+ datelist[1] +"/"+ (parseInt(datelist[0])+543).toString();
      console.log("------",this.date)
    },

  },
};
</script>
